import React from 'react';
import { Container, Content } from '../../components/home/v2/StyledComponents';
import MenuBar from '../../components/home/MenuBar';
import HowItWorks3 from '../../components/home/HowItWorks3';
import Footer from '../../components/home/Footer';
import Layout from '../../layouts/index';

class HowItWorksPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="HowItWorksPage">
        <Container>
          <MenuBar />
          <Content><HowItWorks3 /></Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default HowItWorksPage;
